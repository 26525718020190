




























import { defineComponent } from "@vue/composition-api";
import eventBus from "@monorepo/utils/src/eventBus";
import SelectFilter from "@monorepo/uikit/src/components/common/Select/SelectFilter.vue";
import DatePicker from "@monorepo/uikit/src/components/tableViews/DatePicker.vue";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import moment from "moment";

interface Filters {
  selectInfo: string;
  date: string;
}

export default defineComponent({
  name: "BlockUserModal",
  components: {
    SelectFilter,
    DatePicker,
  },
  props: {
    cbOnBlock: {
      type: Function,
      default: () => 1,
    },
  },
  data(): {
    items: { title: string; value: string }[];
    currentFilters: Filters;
  } {
    return {
      items: [
        {
          title: "Бессрочно",
          value: "all",
        },
        {
          title: "До даты...",
          value: "date",
        },
      ],
      currentFilters: {
        selectInfo: "",
        date: "",
      },
    };
  },
  computed: {
    mustBeBlocked(): boolean {
      return this.currentFilters.selectInfo === "all" ? false : !!Object.entries(this.currentFilters).filter(([, value]) => !value).length;
    },
    minDate() {
      return moment(new Date()).format("YYYY-MM-DD");
    },
  },
  methods: {
    closeModal() {
      eventBus.$emit(MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL, formModalPayload(false, ""));
    },
    blockCb() {
      (this as { cbOnBlock: (currentFilters: Filters) => void }).cbOnBlock?.(this.currentFilters);
      this.closeModal();
    },
  },
});
