var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('TmpBlock',{attrs:{"height":"40"}}):_c('v-combobox',{ref:"element",class:['select-filter select-filter_combobox pa-0 ma-0', { 'base-border': _vm.isBaseBorder }],attrs:{"background-color":"white","append-icon":"mdi-chevron-down","color":"fontBlack","menu-props":{ 'offset-y': true, 'content-class': 'select-filter__options', 'z-index': _vm.zIndex, top: _vm.isOptionsTopPosition },"outlined":"","value":_vm.resultValues,"items":_vm.items,"chips":"","attach":_vm.attach,"placeholder":_vm.title,"multiple":_vm.isMultiple,"clear-icon":"mdi-close-circle","deletable-chips":"","disabled":_vm.isDisabled,"clearable":_vm.isClearable,"item-text":"title","item-value":"value","data-cy":"select-input","id":_vm.id},on:{"change":_vm.changeValue,"click:clear":_vm.resetShowItems},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.isShowChip(item, index))?_c('v-chip',{staticClass:"select-filter__chip",attrs:{"close":""},on:{"click:close":function($event){return _vm.deleteItem(item.value)}}},[(item.icon)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({attrs:{"contain":"","max-width":"20","max-height":"20","src":item.icon}},'v-img',attrs,false),on))]}}],null,true)},[_c('div',[_vm._v(_vm._s(item.title))])]):_c('span',{staticClass:"select-filter__chip-content"},[_vm._v(_vm._s(item.title))])],1):_vm._e(),(_vm.isChips && index === _vm.showItems)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"select-filter__chip",on:{"click":function($event){$event.stopPropagation();return _vm.showAllChips($event)}}},'v-chip',attrs,false),on),[_c('span',[_vm._v("Еще +"+_vm._s(_vm.value.length - _vm.showItems))])])]}}],null,true)},[_c('div',_vm._l((_vm.tooltipText),function(text){return _c('div',{key:text},[_vm._v(_vm._s(text))])}),0)]):_vm._e(),(!_vm.isChips)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"select-filter__selection-single ml-2"},'div',attrs,false),on),[_vm._v(_vm._s(item.title))])]}}],null,true)},[_c('div',[_vm._v(" "+_vm._s(item.title)+" ")])]):_vm._e()]}},{key:"prepend-item",fn:function(){return [(_vm.isShowSelectAllBtn)?_c('v-list-item',[_c('v-btn',{staticClass:"mb-2 mt-2",attrs:{"height":"40","outlined":"","block":"","color":"primary"},on:{"click":_vm.selectAll}},[_c('span',[_vm._v("Выбрать все")])])],1):_vm._e()]},proxy:true},{key:"item",fn:function(ref){
var active = ref.active;
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"select-filter__item ml-4 mr-4",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',{staticClass:"mr-3"},[(item.icon)?_c('v-img',{attrs:{"contain":"","max-width":"20","max-height":"20","src":item.icon}}):_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',[_vm._t("default",[_c('v-list-item-title',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('span',{staticClass:"select-filter__item-text"},[_vm._v(_vm._s(item.title))])])],1)],{"item":item})],2)]}}],null,true)},'v-list-item',attrs,false),on))]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }