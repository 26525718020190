





















































































import Vue from "vue";
import { defineComponent } from "@vue/composition-api";
import _Base from "@monorepo/uikit/src/components/common/Select/_Base.vue";

export default defineComponent({
  name: "SelectFilter",
  extends: _Base,
  data() {
    return {
      resizeObserver: null as null | ResizeObserver,
    };
  },
  props: {
    selectTop: {
      type: Number,
      default: 25,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isChips: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    resultValues(): { title: string; value: string } | { title: string; value: string }[] | undefined {
      return Array.isArray(this.value)
        ? (this.items as { title: string; value: string }[])?.filter((item) => (this.value as string[]).includes(item.value))
        : (this.items as { title: string; value: string }[])?.find((item) => item.value === this.value);
    },
    isShowChip() {
      return (item: any, index: number) => {
        return this.isChips && item && typeof item !== "string" && index <= (this.showItems as number) - 1;
      };
    },
  },
  methods: {
    changeValue(val: { title: string; value: string } | { title: string; value: string }[]) {
      const resultValues = Array.isArray(val) ? val.map((item) => item.value) : val?.value;
      this.$emit("change", resultValues || (this.isMultiple ? [] : null));
    },
    selectAll() {
      (this.$refs.element as HTMLElement)?.blur();
      this.changeValue(this.items as { title: string; value: string }[]);
    },
    showAllChips() {
      this.showItems = Array.isArray(this.value) ? this.value.length : this.showItems;
    },
    attachMenu() {
      const selectElement = document.getElementById(this.id as string) as HTMLElement;
      const selectField = selectElement?.querySelector(".v-select__slot") as HTMLElement;
      let selectMenuTop = 0;
      if (selectElement && selectField) {
        selectMenuTop = selectField.offsetHeight + selectElement.offsetTop;
      }
      const selectMenu = selectElement?.querySelector(".v-menu__content") as HTMLElement;
      if (selectMenu) {
        selectMenu.style.top = `${selectMenuTop + this.selectTop}px`;
      }
    },
  },
  unmounted() {
    this.resizeObserver?.disconnect();
  },
  mounted() {
    this.resizeObserver = new ResizeObserver(() => {
      this.$emit("updateHeight");
      this.attachMenu();
    });
    if ((this.$refs.element as Vue)?.$el) {
      this.resizeObserver.observe((this.$refs.element as Vue).$el);
    }
  },
  watch: {
    loading: {
      handler() {
        this.$emit("updateHeight");
      },
    },
  },
});
